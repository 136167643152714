.DayPicker {
  position: relative;
}

.DayPicker-wrapper {
  width: 300px;
  position: relative;
  padding: 0 6px;
}

.DayPicker-NavBar button {
  margin-top: 1.75rem;
}

.DayPicker-Caption select {
  border-radius: 8px;
  padding: 0.25rem 0.5rem;
  border: 1px solid var(--chakra-colors-gray-300);
  background-color: white;
}

.DayPicker-Day:hover {
  border-radius: 6px;
}

.DayPicker-Day--today {
  color: var(--chakra-colors-blue-500) !important;
}

.DayPicker-Day--today.DayPicker-Day--selected {
  color: white !important;
}

.DayPicker-Day--outside:hover {
  border-radius: 6px;
  background-color: var(--chakra-colors-blue-50);
  cursor: pointer;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  border-radius: 6px;
  background-color: var(--chakra-colors-blue-400);
}

.DayPicker-clear {
  display: flex;
  justify-content: center;

  width: 20%;
  position: absolute;
  top: 86%;
  left: 50%;
  padding: 4px;
  border-radius: 4px;

  font-size: 10px;

  transition: 0.5s ease;
}

.DayPicker-clear:hover {
  cursor: pointer;
  background-color: var(--chakra-colors-gray-100);
}

.DayPicker-Footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding-bottom: 1px;
  position: relative;
}

.DayPicker-Footer button {
  position: absolute;

  background-color: var(--chakra-colors-blue-500);
  color: white;
  padding: 6px 12px;
  border-radius: 6px;

  bottom: 0%;
  left: 80%;

  font-weight: 600;
  font-size: 12px;

  transition: 0.5s ease;
}

.DayPicker-Footer button:hover {
  background-color: var(--chakra-colors-blue-400);
}
