.input-controlled-search{
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-controlled-search > input {
  height: 40px;
  border: solid 2px var(--chakra-colors-gray-200);
  border-radius: 6px;
  width: 100%;
}

.input-controlled-search > input:focus {
  outline: none;
  height: 40px;
  border: solid 2px var(--chakra-colors-gray-200);
  border-radius: 6px;
}

.input-controlled-search > input::placeholder {
  color: transparent;
}