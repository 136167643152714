body {
  margin: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

/* fallback, until https://github.com/CompuIves/codesandbox-client/issues/174 is resolved */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

/* mdl-demo is a custom classname to increases the specificity of our styles. It can be anything. 
 * The idea is that it is easy to extend/override builtin styles with very little effort.
 */

.dropdown{
  width: 100% !important;
}

.dropdown-content{
  width: 100% !important;
  border-radius: 5px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.26) !important;
  border: 1px solid !important;
  border-color: var(--chakra-colors-gray-500) !important;
  padding-inline: 10px !important;
}

.dropdown-trigger{
  border-radius: 5px !important;
  outline-offset: 2px;
  border-color: var(--chakra-colors-gray-300) !important;
  color: var(--chakra-colors-gray-800) !important;
  min-height: 40px !important;
  width: 100% !important;
  /* background-color: #E2E8F0 !important; */
}

.tag{
  padding-inline: 10px;
  background-color: var(--chakra-colors-gray-100);
  border-radius: 5px;
  cursor: default;
  color: var(--chakra-colors-blue-600);
  opacity: 0.7;
}

.tag button{
  display: none;
}

.tag-remove{
  margin-left: 5px;
  font-size: 12px;
}

.mdl-demo .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.mdl-demo .toggle {
  font: normal normal normal 18px/1 "Material Icons";
  color: #555;
  white-space: pre;
  margin-right: 4px;
}

.mdl-demo .toggle.collapsed::after {
  cursor: pointer;
  content: "\E5CF";
  vertical-align: middle;
}

.mdl-demo .toggle.expanded::after {
  cursor: pointer;
  content: "\E5CE";
  vertical-align: middle;
}

/* checkbox styles */
.mdl-demo .checkbox-item {
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  vertical-align: middle;
}

.mdl-demo .checkbox-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 2px solid #aaa;
  transition: all 0.3s ease-in-out;
}

.mdl-demo .checkbox-item:checked:before {
  height: 50%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-top-style: none;
  border-right-style: none;
  border-color: #2196f3;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top::after,
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom::after{
  content:  url("data:image/svg+xml;utf8,<svg  xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 26 26' width='16' height='16' focusable='false' class='chakra-icon css-rwomnz'><path fill='currentColor' d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'></path></svg>");
  color: #555;
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 25%;
}

.search{
  border-bottom: none !important;
  /* background-color: #E2E8F0 !important; */
  padding-inline-start: 8px !important;
}

.search::placeholder{
  color: var(--chakra-colors-gray-700) !important;
}