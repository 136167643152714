.table{
  margin-bottom: 0.5rem;
}

.table .tr :last-child .td {
  border-bottom: 0;
}
 .table .td {
  padding: 2px;
  border-bottom: 1px solid #ddd;
  /* border-right: 1px solid #ddd; */
  background-color: #fff;
  padding-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}

 .table .th {
  padding-left: 10px;
  border-bottom: 1px solid #ddd;
  /* border-right: 1px solid #ddd; */
  height: 56px;
  overflow: hidden;
  /* width: fit-content; */
}

.table.sticky {
  overflow: scroll;
}
.table.sticky .header, .table.sticky .footer {
  position: sticky;
  z-index: 1;
  width: fit-content;
}
.table.sticky .header {
  top: 0;
  box-shadow: 0px 1px 1px #ccc;
  z-index: 0;
}
.table.sticky .footer {
  bottom: 0;
  border-bottom: 1px solid #ccc;
  box-shadow: 0px -2px 5px #ccc;
  height: 55px;
  position: sticky;
  z-index: -1;
  width: fit-content;
  vertical-align: center;
  font-weight: bold;
}

.table.sticky .body {
  position: relative;
  z-index: 0;
  overflow-y: scroll;
}
.table.sticky [data-sticky-td] {
  position: sticky;
  min-width: 150px;
}


.table.sticky [data-sticky-last-left-td] {
  box-shadow: 0px 0px 1px #ccc;
}
.table.sticky [data-sticky-first-right-td] {
  box-shadow: 0px 0px 1px #ccc;
}

.footer-label{
  position: absolute;
}

.input-search > input {
  height: 40px;
  border: solid 2px var(--chakra-colors-gray-200);
  border-radius: 6px;
}

.input-search > input:focus {
  outline: none;
  height: 40px;
  border: solid 2px var(--chakra-colors-gray-200);
  border-radius: 6px;
}

.input-search > input::placeholder {
  color: transparent;
}