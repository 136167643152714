@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

body,
html {
  scrollbar-width: thin;
  scrollbar-color: var(--chakra-colors-gray-300) var(--chakra-colors-gray-100);
}

@media(max-width: 1080px){
  html{
    font-size: 93.75%;
  }
}
@media(max-width: 720px){
  html{
    font-size: 87.5%;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--chakra-colors-gray-200);
  background: var(--chakra-colors-gray-100);
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--chakra-colors-gray-400);
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--chakra-colors-gray-500);
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
}

th > div > div > h2 > input[type="checkbox"]:first-child {
  margin-left: 12px;
}

.header-table-css-global:first-child {
  display: none;
}


.fit-content-select{
  width: fit-content !important;
}
