.time-option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 8px;
  border-radius: 6px;

  transition: 0.5s ease;
}

.time-option:hover {
  cursor: pointer;
  background-color: var(--chakra-colors-blue-400);
  color: white;
}

.time-option-selected {
  background-color: var(--chakra-colors-blue-400);
  color: white;
}
