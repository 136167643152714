.file-label{
  border-radius: 5px;
  display: "inline-block";
  padding: 6px 18px;
  text-align: center;


  transition: all .3s ease;
}

.file-label:hover{
  filter: brightness(0.9)
}